import { DocumentReference, Transaction, WithFieldValue, collection, doc } from "firebase/firestore";
import { firestoreDB } from "../../../firebase";
import { FIRESTORE_COLLECTIONS } from "../../../constants";
import { useTransaction, useUpdateDocument } from "../crudHooks";
import { useCollectionData } from "react-firebase-hooks/firestore";

export const useCreateStaff = ({ businessId, projectId, staffId }: { businessId: any, projectId: any, staffId: any }) => {
    const staffDocRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.BUSINESSES, businessId, FIRESTORE_COLLECTIONS.PROJECTS, projectId, FIRESTORE_COLLECTIONS.STAFF, staffId);

    const customTransaction = async (transaction: Transaction, docRefs: DocumentReference<any>[], data: WithFieldValue<any>[]) => {
        const userDocRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.USERS, data[0]?.mobile);
        const businessInUserRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.USERS, data[0]?.mobile, FIRESTORE_COLLECTIONS.BUSINESSES, businessId);

        const userSnap = await transaction.get(userDocRef);
        if (!userSnap.data() && data[0]) {
            await transaction.set(userDocRef, data[0])
        }
        await transaction.set(businessInUserRef, data[1]);

        await transaction.set(staffDocRef, data[2]);
    }

    const [createStaff, loading, error] = useTransaction([staffDocRef], [], customTransaction);

    return [createStaff, loading, error]
}

export const useUpdateStaff = ({ businessId, projectId, staffId }: { businessId: any, projectId: any, staffId: any }) => {
    const docRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.BUSINESSES, businessId, FIRESTORE_COLLECTIONS.PROJECTS, projectId, FIRESTORE_COLLECTIONS.STAFF, staffId);
    const [updateStaff, loading, error] = useUpdateDocument(docRef);

    return [updateStaff, loading, error]
}

export const useGetStaffData = ({ businessId, projectId }: { businessId: any, projectId: any }) => {
    const collectonRed = collection(firestoreDB, FIRESTORE_COLLECTIONS.BUSINESSES, businessId, FIRESTORE_COLLECTIONS.PROJECTS, projectId, FIRESTORE_COLLECTIONS.STAFF);
    const [value, loading, error] = useCollectionData<any>(collectonRed);
    return [value, loading, error]
}

