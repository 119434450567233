import { collection, doc } from "firebase/firestore";
import { useCreateDocument, useUpdateDocument } from "../crudHooks";
import { FIRESTORE_COLLECTIONS } from "../../../constants";
import { firestoreDB } from "../../../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";

export const useCreateCustomer = ({ businessId, customerId }: { businessId: any, customerId: any }) => {
    const docRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.BUSINESSES, businessId, FIRESTORE_COLLECTIONS.CUSTOMERS, customerId);
    const [createCustomer, loading, error] = useCreateDocument(docRef);

    return [createCustomer, loading, error]
}

export const useUpdateCustomer = ({ businessId, customerId }: { businessId: any, customerId: any }) => {
    const docRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.BUSINESSES, businessId, FIRESTORE_COLLECTIONS.CUSTOMERS, customerId);
    const [updateCustomer, loading, error] = useUpdateDocument(docRef);

    return [updateCustomer, loading, error]
}

export const useGetCustomersData = ({ businessId }: { businessId: any }) => {
    const collectonRed = collection(firestoreDB, FIRESTORE_COLLECTIONS.BUSINESSES, businessId, FIRESTORE_COLLECTIONS.CUSTOMERS);
    const [value, loading, error] = useCollectionData<any>(collectonRed);
    return [value, loading, error]
}
