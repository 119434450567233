import { DocumentData, DocumentReference, UpdateData, WithFieldValue, runTransaction, setDoc, updateDoc } from "firebase/firestore";
import { useLoadingCallback } from "../helpers/useLoadingCallback";
import { firestoreDB } from "../../firebase";

export const useCreateDocument = <T = DocumentData>(
    docRef?: DocumentReference<T> | null,
) => {
    const [createDocument, isLoading, error] = useLoadingCallback(async (data: WithFieldValue<T>) => {
        if (!docRef) {
            throw new Error("docRef empty")
        }
        await setDoc(docRef, data);
    })

    return [createDocument, isLoading, error]
}

export const useUpdateDocument = <T = DocumentData>(
    docRef?: DocumentReference<T> | null,
) => {
    const [updateDocument, isLoading, error] = useLoadingCallback(async (data: UpdateData<T>) => {
        if (!docRef) {
            throw new Error("docRef empty")
        }
        await updateDoc(docRef, data);
    })

    return [updateDocument, isLoading, error]
}

export enum OperationType {
    CREATE,
    UPDATE
}

export const useTransaction = <T = DocumentData>(
    docRefs?: DocumentReference<T>[] | null,
    operations?: OperationType[] | null,
    customTransaction?: (...args: any[]) => Promise<any>
) => {
    const [transactionOnDocuments, isLoading, error] = useLoadingCallback(async (data: any[]) => {
        if (!docRefs || !operations) {
            throw new Error("docRef empty")
        }

        try {
            await runTransaction(firestoreDB, async (transaction) => {
                if (customTransaction) {
                    await customTransaction(transaction, docRefs, data);
                } else {
                    for (let index in docRefs) {
                        switch (operations[index]) {
                            case OperationType.CREATE:
                                await transaction.set(docRefs[index], data[index]);
                                break;

                            case OperationType.UPDATE:
                                await transaction.update(docRefs[index], data[index]);
                                break;

                            default:
                                break;
                        }
                    }
                }
            });
        } catch (e) {
            console.log(e)
            throw new Error("Create operation failed");
        }
    })

    return [transactionOnDocuments, isLoading, error]
}
