import React from 'react'
import { Box, Button, Container, Flex, Grid } from '@mantine/core'
import { useNavigate } from 'react-router-dom';
import Customers from '../customer/Customers';
import Projects from '../project/Projects';

function Dashboard() {
    const navigate = useNavigate();
    return (
        <Container fluid>
            <Flex gap="md" mb={20}>
                <Box w={140}>
                    <Button onClick={() => navigate("/dashboard/new-customer")} fullWidth variant="outline">
                        New Customer
                    </Button>
                </Box>

                <Box w={140}>
                    <Button onClick={() => navigate("/dashboard/new-project")} fullWidth variant="outline">
                        New Project
                    </Button>
                </Box>
            </Flex>
            <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}><Customers /></Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}><Projects /></Grid.Col>
            </Grid>
        </Container>
    )
}

export default Dashboard
