import React from 'react'
import { Alert, Button, Card, Container, Group, TextInput, Title } from '@mantine/core'
import { hasLength, isEmail, matchesField, useForm } from '@mantine/form';
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { firebaseAuth } from '../../../firebase';
import { useNavigatePostLogin } from '../../../hooks/useNavigatePostLogin';
import { IconAlertCircle } from '@tabler/icons-react';

function SignUpWithEmail() {
    const [
        createUserWithEmailAndPassword,
        user,
        loading,
        error,
    ] = useCreateUserWithEmailAndPassword(firebaseAuth);
    const navigatePostLogin = useNavigatePostLogin();
    const form = useForm({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: '',
        },

        validate: {
            email: isEmail('Invalid email'),
            password: hasLength({ min: 6 }, 'Password should be at least 6 characters'),
            confirmPassword: matchesField('password', 'Passwords are not the same'),
        },
    });

    let errorContent
    if (error) {
        if (error.code === "auth/email-already-in-use") {
            errorContent = (
                <Alert icon={<IconAlertCircle size="1rem" />} title="Account exists!" p={10} pb={0} mb={10} color="red">
                </Alert>
            )
        }
    }

    if (user) {
        navigatePostLogin()
    }

    return (
        <Container>
            <Card maw={320} mx="auto" shadow="sm" padding="lg" radius="md" withBorder>
                <Card.Section withBorder inheritPadding py="xs">
                    <Title order={4}> Signup</Title>
                </Card.Section>

                <Card.Section withBorder inheritPadding py="xs">
                    {errorContent}
                    <form onSubmit={form.onSubmit((values) => {
                        createUserWithEmailAndPassword(values.email, values.password)
                    })}>
                        <TextInput label="Email" placeholder="Email" {...form.getInputProps('email')} />
                        <TextInput type="password" label="Password" placeholder="Password" {...form.getInputProps('password')} />
                        <TextInput type="password" label="Confirm" placeholder="Confirm Password" {...form.getInputProps('confirmPassword')} />

                        <Group align="right" mt="md">
                            <Button loading={loading} type="submit">Signup</Button>
                        </Group>
                    </form>
                </Card.Section>
            </Card>
        </Container>
    )
}

export default SignUpWithEmail
