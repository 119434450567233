import { doc } from "firebase/firestore";
import { firestoreDB } from "../../../firebase";
import { FIRESTORE_COLLECTIONS } from "../../../constants";
import { useCreateDocument, useUpdateDocument } from "../crudHooks";
import { useDocumentData, useDocumentDataOnce, useDocumentOnce } from "react-firebase-hooks/firestore";

export const useGetUserOnce = ({ userId }: any) => {
    const docRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.USERS, userId);
    const [value, loading, error] = useDocumentOnce(docRef);

    return [value, loading, error]
}

export const useGetUserData = ({ userId }: any) => {
    const docRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.USERS, userId);
    const [value, loading, error, snapshot] = useDocumentData<any>(docRef);
    return [value, loading, error, snapshot]
}

export const useGetUserDataOnce = ({ userId }: any) => {
    const docRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.USERS, userId);
    const [value, loading, error, snapshot] = useDocumentDataOnce<any>(docRef);
    return [value, loading, error, snapshot]
}

export const useCreateUser = ({ userId }: { userId: any }) => {
    const docRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.USERS, userId);
    const [createUser, loading, error] = useCreateDocument(docRef);

    return [createUser, loading, error]
}

export const useUpdateUser = ({ userId }: { userId: any }) => {
    const docRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.USERS, userId);
    const [updateUser, loading, error] = useUpdateDocument(docRef);

    return [updateUser, loading, error]
}
