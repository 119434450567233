import React from 'react'
import { Box, Image } from '@mantine/core'
import { Link } from 'react-router-dom'

export const GardenLoggerLogo = () => {
  return (
    <Box maw={{ base: 160, md: 190 }} mx="auto" >
      <Link to="/">
        <Image radius="md" src="/Name&Logo.svg" alt="Random image" />
      </Link>
    </Box>
  )
}
