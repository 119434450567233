import React from 'react'
import Main from './Main'
import { IconError404 } from '@tabler/icons-react'
import { Alert, Center } from '@mantine/core'

const PageNotFound = () => {
    return (
        <Main isProtected={false}>
            <Center>
                <Alert icon={<IconError404 size="1rem" />} title="Oops!" color="red">
                    Something went wrong happened! We were unable to locate the information you are seeking!
                </Alert>
            </Center>
        </Main>
    )
}

export default PageNotFound