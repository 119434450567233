import React from 'react'
import { Box, Container } from '@mantine/core'
import { Link } from "react-router-dom"

function Home() {
  return (
    <Container>
      {/* <Box>New here? <Link to="/signup">Signup</Link></Box> */}
      <Box>Already registered? <Link to="/login">Login</Link></Box>
    </Container>
  )
}

export default Home