import React from 'react'
import { Badge, Card, Center, Group, Loader, NavLink, Table, Text } from '@mantine/core'
import { useGetProjectsData } from '../../hooks/firestore/projects/projectCRUDhooks'
import { useCurrentBusiness } from '../../hooks/firestore/business/useCurrentBusiness';
import { Link } from 'react-router-dom';
import initials from 'initials';
import { truncateString } from '../../string-utils';

const Projects = () => {
    const [businessId] = useCurrentBusiness();
    const [projects, loading, error] = useGetProjectsData({ businessId });

    let content = null;
    if (loading) {
        content = (
            <Center mx="auto">
                <Loader size="md" m="lg" variant="bars" />
            </Center>
        )
    } else if (error) {

    } else {
        if (Array.isArray(projects) && projects.length > 0) {
            content = (
                <Table>
                    <tbody>{projects.map(
                        project => (
                            <tr key={project.id}>
                                <td>
                                    <NavLink
                                        key={project.id}
                                        label={project.name}
                                        description={project.address}
                                        leftSection={
                                            <Badge size="xs" variant="filled" color="green" w={20} h={20} p={2}>
                                                {truncateString(initials(project.name), 1).toUpperCase()}
                                            </Badge>
                                        }

                                        component={Link}
                                        to={"/dashboard/" + businessId + "/projects/" + project.id}

                                    />
                                </td>
                            </tr>
                        )
                    )}</tbody>
                </Table>
            )
        } else {
            content = (
                <Center>
                    <Text m="sm" color="dimmed" size="sm">
                        No projects found
                    </Text>
                </Center>
            )
        }
    }

    return (
        <Card withBorder shadow="sm" radius="md">
            <Card.Section withBorder inheritPadding py="xs">
                <Group justify="space-between">
                    <Text size="sm">Projects</Text>
                </Group>
            </Card.Section>

            <Card.Section inheritPadding m="none" p={0}>
                {content}
            </Card.Section>
        </Card>
    )
}

export default Projects
