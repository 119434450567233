import { collection, doc } from "firebase/firestore";
import { firestoreDB } from "../../../firebase";
import { FIRESTORE_COLLECTIONS } from "../../../constants";
import { useCreateDocument, useUpdateDocument } from "../crudHooks";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";

export const useCreateProject = ({ businessId, projectId }: { businessId: any, projectId: any }) => {
    const docRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.BUSINESSES, businessId, FIRESTORE_COLLECTIONS.PROJECTS, projectId);
    const [createProject, loading, error] = useCreateDocument(docRef);

    return [createProject, loading, error]
}

export const useUpdateProject = ({ businessId, projectId }: { businessId: any, projectId: any }) => {
    const docRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.BUSINESSES, businessId, FIRESTORE_COLLECTIONS.PROJECTS, projectId);
    const [updateProject, loading, error] = useUpdateDocument(docRef);

    return [updateProject, loading, error]
}

export const useGetProjectsData = ({ businessId }: { businessId: any }) => {
    const collectonRed = collection(firestoreDB, FIRESTORE_COLLECTIONS.BUSINESSES, businessId, FIRESTORE_COLLECTIONS.PROJECTS);
    const [value, loading, error] = useCollectionData<any>(collectonRed);
    return [value, loading, error]
}

export const useGetProjectData = ({ businessId, projectId }: { businessId: any, projectId: any }) => {
    const docRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.BUSINESSES, businessId, FIRESTORE_COLLECTIONS.PROJECTS, projectId);
    const [value, loading, error, snapshot] = useDocumentData<any>(docRef);
    return [value, loading, error, snapshot]
}
