import React from 'react'
import { Badge, Card, Center, Group, Loader, NavLink, Table, Text } from '@mantine/core'
import { useGetCustomersData } from '../../hooks/firestore/customer/customerCRUDHooks'
import { useCurrentBusiness } from '../../hooks/firestore/business/useCurrentBusiness';
import initials from 'initials';
import { truncateString } from '../../string-utils';

const Customers = () => {
    const [businessId] = useCurrentBusiness();
    const [customers, loading, error] = useGetCustomersData({ businessId });

    let content = null;
    if (loading) {
        content = (
            <Center mx="auto">
                <Loader size="md" m="lg" variant="bars" />
            </Center >
        )
    } else if (error) {

    } else {
        if (Array.isArray(customers) && customers.length > 0) {
            content = (
                <Table>
                    <tbody>{customers.map(
                        customer => (
                            <tr key={customer.id}>
                                <td>
                                    <NavLink
                                        key={customer.id}
                                        label={customer.name}
                                        description={customer.address}
                                        leftSection={
                                            <Badge size="xs" variant="filled" color="black" w={20} h={20} p={2}>
                                                {truncateString(initials(customer.name), 1).toUpperCase()}
                                            </Badge>
                                        }
                                    />
                                </td>
                            </tr>
                        )
                    )}</tbody>
                </Table>
            )
        } else {
            content = (
                <Center>
                    <Text m="sm" color="dimmed" size="sm">
                        No customers found
                    </Text>
                </Center>
            )
        }
    }

    return (
        <Card withBorder shadow="sm" radius="md">
            <Card.Section withBorder inheritPadding py="xs">
                <Group justify="space-between">
                    <Text size="sm">Customers</Text>
                </Group>
            </Card.Section>

            <Card.Section inheritPadding m="none" p={0}>
                {content}
            </Card.Section>
        </Card>
    )
}

export default Customers
