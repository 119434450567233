import React from 'react'
import { Button, Container, Grid, Group, Stack, TextInput, Textarea, Title } from '@mantine/core'
import { hasLength, useForm } from '@mantine/form';
import { useCurrentBusiness } from '../../hooks/firestore/business/useCurrentBusiness';
import { useCreateCustomer } from '../../hooks/firestore/customer/customerCRUDHooks';
import useId from '../../hooks/useId';
import { useNavigate } from 'react-router-dom';
import { isValidPhoneNumber } from 'libphonenumber-js';

const AddCustomer = () => {
    const customerId = useId();

    const navigate = useNavigate();
    const [businessId] = useCurrentBusiness();
    const [createCustomer, loading] = useCreateCustomer({ businessId, customerId });

    const form = useForm({
        initialValues: {
            name: '',
            mobile: '',
            address: '',
        },

        validate: {
            name: hasLength({ min: 1 }, 'Enter customer name'),
            mobile: (value) => (isValidPhoneNumber(value, 'IN') ? null : "Invalid mobile number"),
            address: hasLength({ min: 1 }, 'Enter customer address')
        },
    });

    return (
        <Container fluid>
            <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }} p={0}></Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                    <Title order={5} mb={15}> New Customer</Title>

                    <form onSubmit={form.onSubmit(async (values) => {
                        const customerDetail = {
                            name: values.name,
                            mobile: values.mobile,
                            address: values.address,
                            id: customerId,
                        }
                        await createCustomer(customerDetail)
                        navigate("/dashboard")
                    })}>
                        <Stack>
                            <TextInput label="Name" placeholder="Name" {...form.getInputProps('name')} />
                            <TextInput label="Mobile" placeholder="Mobile" {...form.getInputProps('mobile')} />
                            <Textarea label="Address" placeholder="Address" {...form.getInputProps('address')} />

                            <Group align="right">
                                <Button loading={loading} type="submit">Add</Button>
                            </Group>
                        </Stack>
                    </form>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }} p={0}></Grid.Col>
            </Grid>
        </Container>
    )
}

export default AddCustomer
