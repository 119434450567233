import React from 'react';
import { ActionIcon, Badge, Card, Center, Group, Loader, NavLink, Table, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { IconUserPlus } from '@tabler/icons-react';
import { useGetStaffData } from '../../../hooks/firestore/staff/staffCRUDHooks';
import initials from 'initials';
import { truncateString } from '../../../string-utils';
import { PROJECT_ROLES_DISPLAY_NAME } from '../../../constants';

const StaffList = ({ projectId, businessId }: { projectId: any, businessId: any }) => {
    const [staffList, loading, error] = useGetStaffData({ businessId, projectId });

    let content = null;
    if (loading) {
        content = (
            <Center mx="auto">
                <Loader size="md" m="lg" variant="bars" />
            </Center>
        )
    } else if (error) {

    } else {
        if (Array.isArray(staffList) && staffList.length > 0) {
            content = (
                <Table>
                    <tbody>{staffList.map(
                        staff => (
                            <tr key={staff.id}>
                                <td>
                                    <NavLink
                                        key={staff.id}
                                        label={staff.name}
                                        description={PROJECT_ROLES_DISPLAY_NAME[staff.role as keyof typeof PROJECT_ROLES_DISPLAY_NAME]}
                                        leftSection={
                                            <Badge size="xs" variant="filled" color="green" w={20} h={20} p={2}>
                                                {truncateString(initials(staff.name), 1).toUpperCase()}
                                            </Badge>
                                        }

                                        component={Link}
                                        to={"/dashboard/" + businessId + "/projects/" + projectId + "/staff/" + staff.id}
                                    />
                                </td>
                            </tr>
                        )
                    )}</tbody>
                </Table>
            )
        } else {
            content = (
                <Center>
                    <Text m="sm" color="dimmed" size="sm">
                        You don't have any staff, <Link to={"new-staff"} style={{ textDecoration: "none" }}><Text component="span" inherit color="blue">
                            add here
                        </Text></Link>
                    </Text>
                </Center>
            )
        }
    }

    return (
        <Card withBorder shadow="sm" radius="md">
            <Card.Section withBorder p="xs">
                <Group justify="space-between">
                    <Text size="sm">Staff</Text>
                    <Link to={"new-staff"}>
                        <ActionIcon><IconUserPlus size="1rem" /></ActionIcon>
                    </Link>
                </Group>
            </Card.Section>

            <Card.Section inheritPadding m="none" p={0}>
                {content}
            </Card.Section>
        </Card>
    )
}

export default StaffList
