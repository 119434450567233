export const ASYNC_STATUS = {
    NOT_STARTED: 'not_started',
    PENDING: 'pending',
    SUCCESS: 'success',
    FAILED: 'failed'
};

export const FIRESTORE_COLLECTIONS = {
    BUSINESSES: 'businesses',
    CUSTOMERS: 'customers',
    PROJECTS: 'projects',
    USERS: 'users',
    STAFF: 'staff',
    WORKLOGS: 'work_logs',
};

export const BUSINESS_ROLES = {
    OWNER: 'OWNER',
    PARTNER: 'PARTNER',
    PROJECT_MANAGER: 'PARTNER',
    GARDENER: 'GARDENER',
};

export const PROJECT_ROLES = {
    OWNER: 'OWNER',
    PARTNER: 'PARTNER',
    PROJECT_MANAGER: 'PROJECT_MANAGER',
    GARDENER: 'GARDENER',
};

export const PROJECT_ROLES_DISPLAY_NAME = {
    PROJECT_MANAGER: 'Project Manager',
    GARDENER: 'Gardener',
};

export const TASKS_PERFORMED = {
    Pruning: 'Pruning',
    Mowing: 'Mowing',
    Trimming: 'Trimming',
};

