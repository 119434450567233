import React from 'react'
import { Badge, Box, Button, Group, Modal, MultiSelect, Stack, Text } from '@mantine/core'
import { IconClockStop } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { useUpdateWorkLog } from '../../../hooks/firestore/worklog/worklogCRUDHooks'
import { useDisclosure } from '@mantine/hooks'
import { TASKS_PERFORMED } from '../../../constants'
import { useForm } from '@mantine/form'

const TimelineWorkLog = ({ worklog, businessId, projectId }: { worklog: any, businessId: any, projectId: any }) => {
    const [opened, { open, close }] = useDisclosure(false);
    const [updateWorklog, updatingWorklog] = useUpdateWorkLog({ businessId, projectId, worklogId: worklog.id });

    const form = useForm({
        initialValues: {
            tasks: [],
        }
    });

    return (
        <React.Fragment>
            <Modal zIndex={10} opened={opened} onClose={close} title="End work" centered
            // styles={{ inner: { section: { overflowY: "visible" } } }}
            >
                <form onSubmit={form.onSubmit(async (values) => {
                    const worklogDetail = {
                        endTime: new Date().toISOString(),
                        tasks: values.tasks,
                    };

                    await updateWorklog(worklogDetail);

                    form.reset();

                    close()
                })}>
                    <MultiSelect
                        // zIndex={100100}
                        data={Object.entries(TASKS_PERFORMED).map(([key, value]) => ({ label: value, value: key }))}
                        label="Work completed"
                        placeholder="Pick all the work done"
                        {...form.getInputProps('tasks')}
                    />
                    <Button mt={"sm"} type={"submit"} loading={updatingWorklog} leftSection={<IconClockStop size="1rem" />}>
                        End work
                    </Button>
                </form>
            </Modal>
            <Stack gap={"xs"}>
                <Box>
                    <Text color="dimmed" size="xs">Started at <Text color={"dark"} component="span" fw={500}>{dayjs(worklog.startTime).format("h:mm A")}</Text></Text>
                    {worklog.endTime && <Text color="dimmed" size="xs">Finished at <Text color={"dark"} component="span" fw={500}>{dayjs(worklog.endTime).format("h:mm A")}</Text></Text>}
                    <Text color="dimmed" size="xs">Duration <Text color={"dark"} component="span" >{dayjs.duration(dayjs(worklog.endTime).diff(worklog.startTime)).humanize()}</Text></Text>
                </Box>
                <Group gap={"xs"}>
                    {
                        worklog?.tasks?.map((task: any) => <Badge>{task}</Badge>)
                    }
                </Group>
            </Stack>
            {!worklog.endTime && <Button size={"sm"} px={0} style={{ border: "none" }} variant='outline' onClick={open} loading={updatingWorklog} leftSection={<IconClockStop size="1rem" />}>
                End work
            </Button>}
        </React.Fragment>
    )
}

export default TimelineWorkLog