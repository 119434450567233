import React, { useState } from 'react'
import { Button, Center, Checkbox, ComboboxData, Container, Grid, Group, Loader, Select, Stack, TextInput, Textarea, Title } from '@mantine/core'
import { useForm } from '@mantine/form';
import useId from '../../hooks/useId';
import { useCreateProject } from '../../hooks/firestore/projects/projectCRUDhooks';
import { useCurrentBusiness } from '../../hooks/firestore/business/useCurrentBusiness';
import { useNavigate } from 'react-router-dom';
import { useGetCustomersData } from '../../hooks/firestore/customer/customerCRUDHooks';

const AddProject = () => {
    const projectId = useId();

    const navigate = useNavigate();
    const [searchValue, onSearchChange] = useState('');
    const [businessId] = useCurrentBusiness();
    const [createProject, loading] = useCreateProject({ businessId, projectId });
    const [customers, fetchingCustomers] = useGetCustomersData({ businessId });

    const form = useForm({
        initialValues: {
            name: '',
            customerId: '',
            address: '',
            isSameAddress: false,
        },
    });

    form.getTransformedValues()

    let content;
    if (fetchingCustomers) {
        content = (
            <Center mx="auto">
                <Loader size="md" variant="bars" />
            </Center>
        )
    } else {
        let customersOptions: { value: any; label: any; }[] | ComboboxData = []

        if (Array.isArray(customers)) {
            customersOptions = customers.map(customer => ({
                value: customer.id,
                label: customer.name,
            }));
        }

        content = (
            <form onSubmit={form.onSubmit(async (values) => {
                const projectDetail = {
                    id: projectId,
                    name: values.name,
                    customerId: values.customerId,
                    address: values.address,
                }
                await createProject(projectDetail)
                navigate("/dashboard")
            })}>
                <Stack>
                    <TextInput label="Name" placeholder="Name" {...form.getInputProps('name')} />
                    <Select
                        label="Customer"
                        placeholder="Select Customer"
                        searchable
                        nothingFoundMessage="No customers found"
                        onSearchChange={onSearchChange}
                        searchValue={searchValue}
                        data={customersOptions}
                        required
                        {...form.getInputProps('customerId')}
                    />
                    <Checkbox
                        disabled={!form.values.customerId}
                        label="Same as customer address"
                        {...form.getInputProps('isSameAddress')}
                        onChange={(e) => {
                            form.setFieldValue('isSameAddress', e.target.checked)
                            if (e.target.checked) {
                                if (form.values.customerId) {

                                    if (Array.isArray(customers)) {
                                        let customer = customers.find(customer => customer.id === form.values.customerId);
                                        form.setFieldValue('address', customer?.address)
                                    }
                                }
                            }
                        }}

                    />
                    <Textarea label="Address" disabled={form.values.isSameAddress} placeholder="Address" {...form.getInputProps('address')} />

                    <Group align="right">
                        <Button loading={loading} type="submit">Add</Button>
                    </Group>
                </Stack>
            </form>
        )
    }

    return (
        <Container fluid>
            <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }} p={0}></Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                    <Title order={5} mb={15}> New Project</Title>
                    {content}
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }} p={0}></Grid.Col>
            </Grid>
        </Container>
    )
}

export default AddProject