import React from "react";
import { useOutlet } from "react-router-dom";
import { Alert, Center, Loader } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../firebase";

export const AuthLayout = () => {
    const outlet = useOutlet();
    const [user, loading, error] = useAuthState(firebaseAuth);

    if (loading) {
        return (
            <Center h={"100vh"} mx="auto">
                <Loader size="md" variant="bars" />
            </Center>
        );
    }
    if (error) {
        return (
            <Alert icon={<IconAlertCircle size="1rem" />} title="Bummer!" color="red">
                Something went wrong!
            </Alert>
        );
    }

    if (user)
        return outlet;

    return outlet;
};
