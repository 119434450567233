import React from 'react';
import { Route, createRoutesFromElements, createBrowserRouter } from "react-router-dom";
import Home from './components/home/Home';
import Login from './components/authentication/login/LoginWithPhone';
import Dashboard from './components/dashboard/Dashboard';
import { HomeLayout } from './components/home/HomeLayout';
import { ProtectedLayout } from './components/authentication/ProtectedLayout';
import { AuthLayout } from './components/authentication/AuthLayout';
import SignUp from './components/authentication/signup/SignUpWithEmail';
import AddCustomer from './components/customer/AddCustomer';
import AddProject from './components/project/AddProject';
import PageNotFound from './components/PageNotFound';
import CreateUser from './components/user/CreateUser';
import CreateBusiness from './components/business/CreateBusiness';
import Project from './components/project/Project';
import AddStaff from './components/project/staff/AddStaff';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
    >
      <Route element={<HomeLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
      </Route>

      <Route path="/dashboard" element={<ProtectedLayout />}>
        <Route path="" element={<Dashboard />} />
        <Route path="create-user" element={<CreateUser />} />
        <Route path="create-business" element={<CreateBusiness />} />
        <Route path="new-customer" element={<AddCustomer />} />
        <Route path="new-project" element={<AddProject />} />
        <Route path=":businessId/projects/:projectId" element={<Project />} />
        <Route path=":businessId/projects/:projectId/new-staff" element={<AddStaff />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
);
