import { doc } from "firebase/firestore";
import { OperationType, useTransaction, useUpdateDocument } from "../crudHooks";
import { FIRESTORE_COLLECTIONS } from "../../../constants";
import { firestoreDB } from "../../../firebase";

export const useCreateBusiness = ({ userId, businessId }: { userId: any, businessId: any }) => {
    const businessRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.BUSINESSES, businessId);
    const businessInUserRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.USERS, userId, FIRESTORE_COLLECTIONS.BUSINESSES, businessId);
    const userRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.USERS, userId);
    const [createBusiness, loading, error] = useTransaction([businessRef, businessInUserRef, userRef], [OperationType.CREATE, OperationType.CREATE, OperationType.UPDATE]);

    return [createBusiness, loading, error]
}

export const useUpdateBusiness = ({ businessId }: { userId: any, businessId: any }) => {
    const docRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.BUSINESSES, businessId);
    const [updateBusiness, loading, error] = useUpdateDocument(docRef);

    return [updateBusiness, loading, error]
}
