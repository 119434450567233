import React from 'react';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';

// eslint-disable-next-line
import * as firebase from './firebase';

import ReactDOM from 'react-dom/client';
import './index.css';
import { router } from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from "react-router-dom";
import { MantineProvider } from '@mantine/core';
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"
import dayjs from 'dayjs';
import { Notifications } from '@mantine/notifications';

dayjs.extend(duration)
dayjs.extend(relativeTime)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <MantineProvider>
      <Notifications />
      <RouterProvider router={router} />
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
