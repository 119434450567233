import React from 'react'
import { Button, Container, Grid, Group, Stack, TextInput, Title } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form';
import { firebaseAuth } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCreateBusiness } from '../../hooks/firestore/business/businessCRUDhooks';
import { useGetUserData } from '../../hooks/firestore/user/userCRUDHooks';
import useId from '../../hooks/useId';
import { Navigate } from 'react-router-dom';
import { getUserId } from '../../utils';
import { BUSINESS_ROLES } from '../../constants';

const CreateBusiness = () => {
    const businessId = useId();

    const [authenticatedUser] = useAuthState(firebaseAuth);
    const [user] = useGetUserData({ userId: getUserId(authenticatedUser) });
    const [createBusiness, creatingBusiness] = useCreateBusiness({ userId: getUserId(authenticatedUser), businessId });

    const form = useForm({
        initialValues: {
            businessName: '',
            id: businessId,
        },

        validate: {
            businessName: isNotEmpty("Enter business name")
        }
    });

    if (user && user.defaultBusinessId) {
        return (
            <Navigate to={"/dashboard"} />
        )
    }

    return (
        <Container fluid>
            <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }} p={0}></Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                    <Title order={5} mb={15}> Enter your business details</Title>

                    <form onSubmit={form.onSubmit(async (values) => {
                        const businessDetail = {
                            businessName: values.businessName,
                            owner: getUserId(authenticatedUser)
                        }

                        const businessInUserDetail = {
                            ...businessDetail,
                            role: BUSINESS_ROLES.OWNER
                        }

                        await createBusiness([businessDetail, businessInUserDetail, { defaultBusinessId: businessId }])
                    })}>
                        <Stack>
                            <TextInput label="Business Name" placeholder="Business Name" {...form.getInputProps('businessName')} />

                            <Group align="right">
                                <Button loading={creatingBusiness} type="submit">Next</Button>
                            </Group>
                        </Stack>
                    </form>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }} p={0}></Grid.Col>
            </Grid>
        </Container>
    )
}

export default CreateBusiness
