import {
    ApplicationVerifier,
    Auth,
    AuthError,
    ConfirmationResult,
    signInWithPhoneNumber as firebaseSignInWithPhoneNumber,
    UserCredential,
} from 'firebase/auth';
import { useCallback, useState } from 'react';
import { SignInWithPhoneNumber } from './types';

const useSignInWithPhone = (auth: Auth): SignInWithPhoneNumber => {
    const [error, setError] = useState<AuthError>();
    const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult>();
    const [loggedInUser, setLoggedInUser] = useState<UserCredential>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isOTPSent, setIsOTPSent] = useState<boolean>(false);
    const [confirmingOTP, setConfirmingOTP] = useState<boolean>(false);

    const confirmOTP = useCallback(
        async (otp: string) => {
            setConfirmingOTP(true);
            setError(undefined);
            try {
                const user = await confirmationResult?.confirm(otp);
                setLoggedInUser(user);

                return user;
            } catch (err) {
                setError(err as AuthError);
            } finally {
                setLoading(false);
            }
        },
        [confirmationResult]
    )

    const signInWithPhone = useCallback(
        async (phoneNumber: string, appVerifier: ApplicationVerifier) => {

            setLoading(true);
            setError(undefined);
            try {
                const confirmationResult = await firebaseSignInWithPhoneNumber(auth, phoneNumber, appVerifier);
                setConfirmationResult(confirmationResult);
                setIsOTPSent(true);

                return confirmOTP;
            } catch (err) {
                setError(err as AuthError);
            } finally {
                setLoading(false);
            }
        },
        [auth, confirmOTP]
    );

    return [signInWithPhone, loggedInUser, loading, error, isOTPSent, confirmOTP, confirmingOTP];
};

export default useSignInWithPhone;
