import { collection, doc, orderBy, query } from "firebase/firestore";
import { firestoreDB } from "../../../firebase";
import { FIRESTORE_COLLECTIONS } from "../../../constants";
import { useCreateDocument, useUpdateDocument } from "../crudHooks";
import { useCollectionData } from "react-firebase-hooks/firestore";

export const useCreateWorkLog = ({ businessId, projectId, worklogId }: { businessId: any, projectId: any, worklogId: any }) => {
    const docRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.BUSINESSES, businessId, FIRESTORE_COLLECTIONS.PROJECTS, projectId, FIRESTORE_COLLECTIONS.WORKLOGS, worklogId);
    const [createWorkLog, loading, error] = useCreateDocument(docRef);

    return [createWorkLog, loading, error]
}

export const useUpdateWorkLog = ({ businessId, projectId, worklogId }: { businessId: any, projectId: any, worklogId: any }) => {
    const docRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.BUSINESSES, businessId, FIRESTORE_COLLECTIONS.PROJECTS, projectId, FIRESTORE_COLLECTIONS.WORKLOGS, worklogId);
    const [updateWorkLog, loading, error] = useUpdateDocument(docRef);

    return [updateWorkLog, loading, error]
}

export const useGetWorkLogsData = ({ businessId, projectId }: { businessId: any, projectId: any }) => {

    const collectonRef = collection(firestoreDB, FIRESTORE_COLLECTIONS.BUSINESSES, businessId, FIRESTORE_COLLECTIONS.PROJECTS, projectId, FIRESTORE_COLLECTIONS.WORKLOGS);
    const queryRef = query(collectonRef, orderBy("startTime", "desc"));

    const [value, loading, error] = useCollectionData<any>(queryRef);
    return [value, loading, error]
}

// export const useGetWorkLogData = ({ businessId, projectId, worklogId }: { businessId: any, projectId: any, worklogId: any }) => {
//     const docRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.BUSINESSES, businessId, FIRESTORE_COLLECTIONS.PROJECTS, projectId);
//     const [value, loading, error, snapshot] = useDocumentData<any>(docRef);
//     return [value, loading, error, snapshot]
// }
