import { Navigate, Outlet } from "react-router-dom";
import Main from "../Main";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../firebase";

export const HomeLayout = () => {
    const [user] = useAuthState(firebaseAuth);

    if (user) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <div>
            <Main isProtected={false}>
                <Outlet />
            </Main>
        </div>
    )
};
