import React from 'react'
import { Center, Container, Grid, Loader, Stack, Title } from '@mantine/core'
import { useParams } from 'react-router-dom'
import { useGetProjectData } from '../../hooks/firestore/projects/projectCRUDhooks';
import PageNotFound from '../PageNotFound';
import StaffList from './staff/StaffList';
import WorkLog from './worklog/WorkLogs';

const Project = () => {
    const params = useParams();
    const projectId = params.projectId;
    const businessId = params.businessId;

    const [project, loading, error] = useGetProjectData({ businessId, projectId });

    if (loading) {
        return (
            <Center mx="auto">
                <Loader size="md" variant="bars" />
            </Center>
        )
    } else if (error) {
        return (
            <PageNotFound />
        )
    }

    return (
        <Container fluid>
            <Stack>
                <Title order={4}>{project.name}</Title>

                <Grid>
                    <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                        <StaffList projectId={projectId} businessId={businessId} />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 3 }} p={0}>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 3 }} p={0}>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                        <WorkLog projectId={projectId} businessId={businessId} />
                    </Grid.Col>
                </Grid>
            </Stack>
        </Container>
    )
}

export default Project
