import React from 'react'
import { Button, Container, Grid, Group, Stack, TextInput, Title, rem } from '@mantine/core'
import { hasLength, useForm } from '@mantine/form';
import { firebaseAuth } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCreateUser, useGetUserData } from '../../hooks/firestore/user/userCRUDHooks';
import { Navigate } from 'react-router-dom';
import { DatePickerInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import { getUserId } from '../../utils';

const CreateUser = () => {
    const [authenticatedUser] = useAuthState(firebaseAuth);
    const [user] = useGetUserData({ userId: getUserId(authenticatedUser) });
    const [createUser, loading] = useCreateUser({ userId: getUserId(authenticatedUser) });

    const form = useForm({
        initialValues: {
            name: '',
            dob: null,
        },

        validate: {
            name: hasLength({ min: 1 }, 'Enter your name'),
            dob: (value) => !value ? 'Please select your dob' : null
        },

        transformValues: (values) => ({
            ...values,
            dob: values?.dob ? new Date(values?.dob)?.toISOString() : null,
        }),
    });

    if (user && !user.business) {
        return (
            <Navigate to={"/dashboard/create-business"} />
        )
    }

    return (
        <Container fluid>
            <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }} p={0}></Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                    <Title order={5} mb={15}> Enter your details</Title>

                    <form onSubmit={form.onSubmit((values) => {
                        const userDetail = {
                            id: authenticatedUser?.uid,
                            name: values.name,
                            mobile: authenticatedUser?.phoneNumber,
                        }

                        createUser(userDetail)
                    })}>
                        <Stack>
                            <TextInput label="Name" placeholder="Enter name" {...form.getInputProps('name')} />
                            <DatePickerInput
                                leftSection={<IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />}
                                leftSectionPointerEvents="none"
                                minDate={new Date("1900-01-01")}
                                maxDate={new Date()}

                                label="Date of birth"
                                placeholder="Select your date of birth"
                                {...form.getInputProps('dob')}
                            />

                            <Group align="right" mt="md">
                                <Button loading={loading} type="submit">Next</Button>
                            </Group>
                        </Stack>
                    </form>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }} p={0}></Grid.Col>
            </Grid>
        </Container>
    )
}

export default CreateUser
