// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDPJ2CbJ_5dIlZq7Mo4jVuPXdJW6GTpnU0",
    authDomain: "garden-journel.firebaseapp.com",
    projectId: "garden-journel",
    storageBucket: "garden-journel.appspot.com",
    messagingSenderId: "845896196907",
    appId: "1:845896196907:web:df7ef2d17c58f502c69b6e",
    measurementId: "G-XTPQHTJTK7"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const firestoreDB = getFirestore(firebaseApp);
