import React from 'react'
import { Box, Button, Card, Center, Container, FocusTrap, Grid, Group, Input, PinInput, Stack, TextInput, Title } from '@mantine/core'
import { hasLength, useForm } from '@mantine/form';
import { firebaseAuth } from '../../../firebase';
import { useNavigatePostLogin } from '../../../hooks/useNavigatePostLogin';
import { IconDeviceMobile } from '@tabler/icons-react';
import useSignInWithPhone from '../../../hooks/auth/useSignInWithPhone';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { RecaptchaVerifier } from 'firebase/auth';

function LoginWithPhone() {
    const [
        singInWithPhone,
        user,
        loading,
        error,
        isOTPSent,
        confirmOTP,
        confirmingOTP
    ] = useSignInWithPhone(firebaseAuth);
    const navigatePostLogin = useNavigatePostLogin();


    const form = useForm({
        initialValues: {
            mobile: '',
            otp: '',
        },

        validate: {
            mobile: (value) => (isValidPhoneNumber(value, 'IN') ? null : "Invalid mobile number"),
            otp: (value) => isOTPSent ? hasLength(6, 'Enter OTP')(value) : null,
        },
    });

    if (user) {
        navigatePostLogin()
    }

    return (
        <Container>
            <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }} p={0}></Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                    <Center>
                        <Card w={"90%"} shadow="sm" radius="md" withBorder>
                            <Card.Section withBorder inheritPadding py="xs">
                                <Title order={4}> Login</Title>
                            </Card.Section>

                            <Card.Section withBorder inheritPadding p="lg">
                                <form onSubmit={form.onSubmit((values) => {
                                    if (!isOTPSent) {
                                        let appVerifier = new RecaptchaVerifier('recaptcha-container', {
                                            'callback': () => {
                                            }
                                        }, firebaseAuth);
                                        singInWithPhone("+91" + values.mobile, appVerifier)
                                    } else {
                                        confirmOTP(values.otp)
                                    }
                                })}>
                                    <Stack mb={"md"}>
                                        <TextInput
                                            leftSection={<IconDeviceMobile size="1rem" />}
                                            label="Mobile"
                                            placeholder="Enter your mobile no."
                                            {...form.getInputProps('mobile')}
                                        />
                                        {isOTPSent && (
                                            <Box>
                                                <Input.Label>Verify OTP</Input.Label>
                                                <FocusTrap active={true}>
                                                    <PinInput placeholder="" aria-label="One time code" type="number" length={6} {...form.getInputProps('otp')} error={!!error} />
                                                </FocusTrap>
                                            </Box>
                                        )}
                                    </Stack>
                                    <div id="recaptcha-container"></div>
                                    <Group align="right" mt="md">
                                        <Button type="submit" loading={loading || confirmingOTP}>{!isOTPSent ? "Get OTP" : "Veify"}</Button>
                                    </Group>
                                </form>
                            </Card.Section>
                        </Card>
                    </Center>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }} p={0}></Grid.Col>
            </Grid>
        </Container>

    )
}

export default LoginWithPhone
