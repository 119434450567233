import { Navigate, Outlet, Link, useLocation } from "react-router-dom";
import Main from "../Main";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../firebase";
import { Center, Loader } from "@mantine/core";
import { useGetUserData } from "../../hooks/firestore/user/userCRUDHooks";
import { useCurrentBusiness } from "../../hooks/firestore/business/useCurrentBusiness";
import { getUserId } from "../../utils";

export const ProtectedLayout = () => {
    const [authenticatedUser, authInProgress] = useAuthState(firebaseAuth);
    const [user, loading] = useGetUserData({ userId: getUserId(authenticatedUser) });
    const [currentBusinessId, setCurrentBusiness] = useCurrentBusiness();
    const location = useLocation();

    if (authInProgress || loading) {
        return (
            <Center h={"100vh"} mx="auto">
                <Loader size="md" variant="bars" />
            </Center>
        );
    }

    if (!authenticatedUser) {
        return <Navigate to="/" />;
    }

    // START: New User flow
    if (!user && location.pathname !== "/dashboard/create-user") {
        return <Navigate to="/dashboard/create-user" />;
    }

    if (user && !user.defaultBusinessId && location.pathname !== "/dashboard/create-business") {
        return <Navigate to="/dashboard/create-business" />;
    }
    // END: New User flow

    if (user && user.defaultBusinessId && currentBusinessId !== user.defaultBusinessId) {
        setCurrentBusiness(user.defaultBusinessId)
    }

    return (
        <div>
            <nav>
                <Link to="/settings">Settings</Link>
                <Link to="/profile">Profile</Link>
            </nav>
            <Main isProtected={true}>
                <Outlet />
            </Main>
        </div>
    )
};
