import React, { useCallback } from 'react'
import { Button, Card, Center, Group, Loader, Text, Timeline } from '@mantine/core'
import { IconClockPlay, IconProgress, IconProgressCheck } from '@tabler/icons-react'
import { useCreateWorkLog, useGetWorkLogsData } from '../../../hooks/firestore/worklog/worklogCRUDHooks'
import useId from '../../../hooks/useId'
import { useAuthState } from 'react-firebase-hooks/auth'
import { firebaseAuth } from '../../../firebase'
import { getUserId } from '../../../utils'
import { useGetUserData } from '../../../hooks/firestore/user/userCRUDHooks'
import dayjs from 'dayjs'
import TimelineWorkLog from './TimelineWorklog'

const shouldEnableStartButton = (worklogs: any) => {
    return Array.isArray(worklogs) && worklogs[0] ? worklogs[0].startTime && worklogs[0].endTime : true
}

const WorkLogs = ({ projectId, businessId }: { projectId: any, businessId: any }) => {
    const worklogId = useId();

    const [authenticatedUser] = useAuthState(firebaseAuth);
    const [user] = useGetUserData({ userId: getUserId(authenticatedUser) });
    const [worklogs, loading, error] = useGetWorkLogsData({ businessId, projectId });
    const [createWorklog, creatingWorklog] = useCreateWorkLog({ businessId, projectId, worklogId });

    const onStartWork = useCallback(
        () => {
            const worklogDetail = {
                id: worklogId,
                loggedBy: user.name,
                startTime: new Date().toISOString(),
            };

            createWorklog(worklogDetail);
        },
        [createWorklog, user?.name, worklogId],
    )

    let content = null;
    if (loading) {
        content = (
            <Center mx="auto">
                <Loader size="md" m="lg" variant="bars" />
            </Center>
        )
    } else if (error) {

    } else {
        let startWorkButton;

        let enableStartButton = shouldEnableStartButton(worklogs);

        if (enableStartButton) {
            startWorkButton = (
                <Button size={"sm"} p={0} mb={"sm"} style={{ border: "none" }} variant='outline' onClick={onStartWork} loading={creatingWorklog} leftSection={<IconClockPlay size="1rem" />}>
                    Start work
                </Button>
            )
        }

        let timeline

        if (Array.isArray(worklogs) && worklogs.length > 0) {
            timeline = (
                <Timeline active={!enableStartButton ? 1 : -1} bulletSize={24} lineWidth={2}>
                    {
                        worklogs.map(
                            worklog => (
                                <Timeline.Item
                                    key={worklog.id}
                                    title={dayjs(worklog.startTime).format("MMM D, YYYY")}
                                    bullet={worklog.endTime ? <IconProgressCheck size={15} /> : <IconProgress size={15} />}
                                    lineVariant={worklog.endTime ? "dotted" : "solid"}
                                >
                                    <TimelineWorkLog
                                        key={worklog.id}
                                        worklog={worklog}
                                        businessId={businessId}
                                        projectId={projectId}
                                    />
                                </Timeline.Item>
                            )
                        )
                    }
                </Timeline>
            )
        } else {
            // content = (
            //     <Center>
            //         <Text m="sm" color="dimmed" size="sm">
            //             You don't have any staff, <Link to={"new-staff"} style={{ textDecoration: "none" }}><Text component="span" inherit color="blue">
            //                 add here
            //             </Text></Link>
            //         </Text>
            //     </Center>
            // )
        }

        content = (
            <React.Fragment>
                {startWorkButton}
                {timeline}
            </React.Fragment>
        )
    }

    return (
        <Card withBorder shadow="sm" radius="md">
            <Card.Section withBorder p="xs">
                <Group justify="space-between">
                    <Text size="sm">Work log</Text>
                </Group>
            </Card.Section>

            <Card.Section inheritPadding m="none" p={"sm"}>
                {content}
            </Card.Section>
        </Card>
    )
    // console.log(projectId, businessId)
    // return (
    //     <Container fluid>
    //         <CheckIn />
    //         <Timeline active={1} bulletSize={24} lineWidth={2}>
    //             <Timeline.Item bullet={<IconGitBranch size={12} />} title="New branch">
    //                 <Text color="dimmed" size="sm">You&apos;ve created new branch <Text variant="link" component="span" inherit>fix-notifications</Text> from master</Text>
    //                 <Text size="xs" mt={4}>2 hours ago</Text>
    //             </Timeline.Item>

    //             {/* <Timeline.Item bullet={<IconGitCommit size={12} />} title="Commits">
    //                 <Text color="dimmed" size="sm">You&apos;ve pushed 23 commits to<Text variant="link" component="span" inherit>fix-notifications branch</Text></Text>
    //                 <Text size="xs" mt={4}>52 minutes ago</Text>
    //             </Timeline.Item>

    //             <Timeline.Item title="Pull request" bullet={<IconGitPullRequest size={12} />} lineVariant="dashed">
    //                 <Text color="dimmed" size="sm">You&apos;ve submitted a pull request<Text variant="link" component="span" inherit>Fix incorrect notification message (#187)</Text></Text>
    //                 <Text size="xs" mt={4}>34 minutes ago</Text>
    //             </Timeline.Item>

    //             <Timeline.Item title="Code review" bullet={<IconMessageDots size={12} />}>
    //                 <Text color="dimmed" size="sm"><Text variant="link" component="span" inherit>Robert Gluesticker</Text> left a code review on your pull request</Text>
    //                 <Text size="xs" mt={4}>12 minutes ago</Text>
    //             </Timeline.Item> */}
    //         </Timeline>
    //     </Container>
    // )
}

export default WorkLogs
