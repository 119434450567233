import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Grid, Group, Select, Stack, TextInput, Title } from '@mantine/core';
import { hasLength, useForm } from '@mantine/form';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useCreateStaff } from '../../../hooks/firestore/staff/staffCRUDHooks';
import useId from '../../../hooks/useId';
import { PROJECT_ROLES_DISPLAY_NAME } from '../../../constants';

const AddStaff = () => {
    const staffId = useId();

    const navigate = useNavigate();
    const params = useParams();
    const projectId = params.projectId;
    const businessId = params.businessId;
    const [createStaff, loading] = useCreateStaff({ businessId, projectId, staffId });

    const form = useForm({
        initialValues: {
            name: '',
            mobile: '',
            role: '',
        },

        validate: {
            name: hasLength({ min: 1 }, 'Enter customer name'),
            mobile: (value) => (isValidPhoneNumber(value, 'IN') ? null : "Invalid mobile number"),
            role: hasLength({ min: 1 }, "Please select role")
        },

        transformValues: (values) => {
            return {
                ...values,
                mobile: "+91" + values.mobile
            }
        }
    });

    return (
        <Container fluid>
            <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }} p={0}></Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                    <Title order={5} mb={15}> New staff</Title>

                    <form onSubmit={form.onSubmit(async (values) => {
                        const basicDetail = {
                            name: values.name,
                            mobile: values.mobile,
                        }
                        const staffDetail = {
                            ...basicDetail,
                            role: values.role,
                            id: staffId,
                        }
                        const userDetail = {
                            ...basicDetail,
                            defaultBusinessId: businessId,
                        }
                        const businessInUserDetail = {
                            ...staffDetail,
                            role: values.role,
                            id: businessId,
                        }
                        await createStaff([userDetail, businessInUserDetail, staffDetail])
                        navigate("/dashboard/" + businessId + "/projects/" + projectId)
                    })}>

                        <Stack>
                            <TextInput required label="Name" placeholder="Name" {...form.getInputProps('name')} />
                            <TextInput required type="number" label="Mobile" placeholder="Mobile" {...form.getInputProps('mobile')} />
                            <Select
                                label="Role"
                                placeholder="Select role"
                                nothingFoundMessage="No role found"
                                data={Object.entries(PROJECT_ROLES_DISPLAY_NAME).map(([key, value]) => ({ label: value, value: key }))}
                                required
                                {...form.getInputProps('role')}
                            />

                            <Group align="right">
                                <Button loading={loading} type="submit">Add</Button>
                            </Group>
                        </Stack>
                    </form>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }} p={0}></Grid.Col>
            </Grid>
        </Container>
    )
}

export default AddStaff
