import React from 'react';
import {
  AppShell,
  Burger,
  Center,
  Button,
  Group,
  rem,
} from '@mantine/core';
import { useSignOut } from 'react-firebase-hooks/auth';
import { firebaseAuth } from '../firebase';
import { useDisclosure } from '@mantine/hooks';
import { GardenLoggerLogo } from './GardenLoggerLogo';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';

export default function Main({ children, isProtected = false }: { children: any, isProtected: Boolean }) {
  const [signOut, loading] = useSignOut(firebaseAuth);

  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(false);

  return (
    <AppShell
      header={{ height: { base: 50, md: 70 } }}
      navbar={{
        width: { sm: 200, lg: 300 },
        breakpoint: 'sm',
        collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
      }}
      footer={{ height: 60 }}
      padding={"md"}
    >
      <AppShell.Header p="md">
        <Group h="100%" px="md">
          <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" hidden={!isProtected} size="sm" />
          <Burger opened={desktopOpened} onClick={toggleDesktop} visibleFrom="sm" hidden={!isProtected} size="sm" />
          <GardenLoggerLogo />
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p="md">
        <Button onClick={async () => {
          const success = await signOut();
          if (success) {
            notifications.show({
              color: 'teal',
              title: 'Signed out successfully!',
              message: '',
              icon: <IconCheck style={{ width: rem(16), height: rem(16) }} />,
              loading: false,
              autoClose: 2000,
            });
          }
        }} loading={loading}>Logout</Button>
      </AppShell.Navbar>

      <AppShell.Main>
        {children}
      </AppShell.Main>

      <AppShell.Footer p="md">
        <Center>
          © Garden Journal by Home Greenery
        </Center>
      </AppShell.Footer>
    </AppShell>
  );
}
