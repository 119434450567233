import { useState } from 'react';
import { v1 as uuidV1, v3 as uuidV3, v4 as uuidV4, v5 as uuidV5 } from 'uuid';

const idGenerators: any = {
    v1: uuidV1,
    v3: uuidV3,
    v4: uuidV4,
    v5: uuidV5
};

function useId(version = 'v4', ...uuidArgs: any[]) {
    if (!(version in idGenerators)) {
        throw new Error('Invalid version for id generator');
    }

    // Utilize useState instead of useMemo because React
    // makes no guarantees that the memo store is durable
    let [id] = useState(() => {
        return idGenerators[version](...uuidArgs);
    });

    return id;
}

export default useId;
